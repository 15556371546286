import Input from '../components/form/Input';
import { get } from 'lodash';
import OpponentScorerModel from '../models/OpponentScorerModel';
import { GoalIcon } from '../components/Icons';
import DatePicker from '../components/form/DatePicker';

export default {
    baseUrl: '/opponent-scorers',
    icon: GoalIcon,
    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
    titles: {
        list: 'Opponent scorers',
        create: 'Add a new opponent scorer',
        update: 'Update %firstName% %lastName%',
        delete: 'Delete %firstName% %lastName%',
        vars: {
            '%firstName%': 'firstName',
            '%lastName%': 'lastName'
        }
    },
    calls: {
        list: OpponentScorerModel.list,
        create: OpponentScorerModel.create,
        update: OpponentScorerModel.edit,
        delete: OpponentScorerModel.remove,
        read: OpponentScorerModel.read
    },
    list: {
        displayedColumns: [
            {
                field: 'firstName',
                title: 'First name'
            },
            {
                field: 'lastName',
                title: 'Last name'
            }
        ],
        filters: [
            {
                title: 'First name',
                filter: (item, value) => get(item, 'firstName').toLowerCase().indexOf(value.toLowerCase()) !== -1
            },
            {
                title: 'Last name',
                filter: (item, value) => get(item, 'lastName').toLowerCase().indexOf(value.toLowerCase()) !== -1
            }
        ],
        paginate: true,
        rowsPerPage: 20,

    },
    form: {
        blocks: [
            [
                {
                    component: Input,
                    field: 'firstName',
                    props: {
                        label: 'First name'
                    }
                },
                {
                    component: Input,
                    field: 'lastName',
                    props: {
                        label: 'Last name'
                    }
                },
                {
                    component: DatePicker,
                    field: 'birthDate',
                    props: {
                        label: 'Birth date'
                    },
                    defaultValue: null
                }
            ]
        ]
    }
};
