import React, { useState } from 'react';
import { Avatar, Badge, Button, Dialog, DialogActions, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import AutocompleteSelect from './AutocompleteSelect';
import TimeInput from './TimeInput';
import GameTimeInput from './GameTimeInput';
import Checkbox from './Checkbox';
import Collection from './Collection';
import GamePlayerGoal from './GamePlayerGoal';
import './GamePlayer.css';

export default props => {
    const [dialogShown, setDialogShown] = useState(false);

    const renderAccurateField = () => {
        if (!props.player || !props.player.player || props.player.player.picture) {
            return null;
        }

        return `${props.player.player.firstName.substr(0, 1)}${props.player.player.lastName.substr(0, 1)}`;
    };

    const renderSrc = () => {
        return props.player && props.player.player && props.player.player.picture
            ? props.player.player.picture.displayPath : null;
    };

    const getCardsSelectValue = () => {
        if (!props.player) {
            return null;
        }

        if (props.player.penaltyCards.length === 0) {
            return 'n';
        }
        if (props.player.penaltyCards.length === 2) {
            return 'yr';
        }

        return props.player.penaltyCards[0].type;
    };

    const handleClick = () => {
        if (props.player) {
            setDialogShown(true);
        } else if (props.openable) {
            props.onAdd(props.x, props.y);
            setDialogShown(true);
        }
    };

    const handleRemoveClick = () => {
        setDialogShown(false);
        props.onRemove(props.x, props.y);
    };

    const handleDialogClose = () => {
        setDialogShown(false);
    };

    const handlePropertyChanged = (property, value) => {
        const newPlayer = {...props.player};

        newPlayer[property] = value;

        props.onChange(props.x, props.y, newPlayer);
    };

    const handlePlayerChange = (newPlayer) => {
        handlePropertyChanged('player', newPlayer);
    };

    const handleTimeChange = (newTime) => {
        handlePropertyChanged('time', newTime);
    };

    const handleCardsChange = (e) => {
        switch (e.target.value) {
            case 'n':
                handlePropertyChanged('penaltyCards', []);
                break;
            case 'y':
            case 'r':
                handlePropertyChanged('penaltyCards', [{ time: '', extraTime: '', type: e.target.value }]);
                break;
            default:
                handlePropertyChanged('penaltyCards', [
                    { time: '', extraTime: '', type: 'y' },
                    { time: '', extraTime: '', type: 'r' }
                ]);
        }
    };

    const handleCardTimeChange = (cardType, property, newValue) => {
        const newPenaltyCards = [...props.player.penaltyCards];
        const i = newPenaltyCards.findIndex(c => c.type === cardType);

        if (i === -1) {
            return;
        }

        newPenaltyCards[i][property] = newValue;

        handlePropertyChanged('penaltyCards', newPenaltyCards);
    };

    const handleCaptainChanged = (newValue) => handlePropertyChanged('captain', newValue);

    const handleGoalsChange = newGoals => handlePropertyChanged('goals', newGoals);

    const handleReplacesChange = (player) => handlePropertyChanged('replaces', player);

    const handleReplacesAtChange = (minute) => handlePropertyChanged('replacesAt', minute);

    const handleDragStart = () => {
        if (props.player && props.player.player) {
            props.onDragStart(props.playerIndex);
        }
    };

    const handleDragEnd = () => props.onDragEnd();

    const handleDragOver = e => {
        e.preventDefault();
        e.stopPropagation();

        props.onDragOver(props.playerIndex, props.x, props.y);
    }

    const handleDragLeave = () => {
        props.onDragLeave();
    }

    const handleDrop = e => {
        e.preventDefault();
        e.stopPropagation();

        props.onDrop(props.x, props.y, props.playerIndex);
    }

    const handleDragEnter = e => {
        e.preventDefault();
    }

    const yellowCard = props.player ? props.player && props.player.penaltyCards.find(c => c.type === 'y') : null;
    const redCard = props.player ? props.player && props.player.penaltyCards.find(c => c.type === 'r') : null;

    return <div
        style={{ boxSizing: 'border-box', padding: '1rem', border: props.draggedOver ? '2px gray dashed' : 'none' }}
        draggable={!!props.player}
        onDragEnter={handleDragEnter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
    >
        <Badge className="RedBadge" anchorOrigin={{ vertical: 'top', horizontal: 'left' }} color="primary" invisible={!redCard} variant="dot">
            <Badge className="YellowBadge" anchorOrigin={{ vertical: 'top', horizontal: 'left' }} color="primary" invisible={!yellowCard} variant="dot">
                <Badge anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} color="error" invisible={!props.player || !props.player.goals.length} badgeContent={props.player ? props.player.goals.length : ''}>
                    <Badge color="primary" invisible={!props.player || !props.player.time} badgeContent={props.player ? props.player.time : ''}>
                        <Avatar
                            style={{
                                visibility: props.openable || props.player ? 'visible' : 'hidden'
                            }}
                            src={renderSrc()}
                            onClick={handleClick}
                        >
                            {renderAccurateField()}
                        </Avatar>
                    </Badge>
                </Badge>
            </Badge>
        </Badge>
        <Dialog open={dialogShown} onClose={handleDialogClose}>
            <div style={{ padding: '1rem' }}>
                <AutocompleteSelect
                    style={{ width: 'calc(100% - 2rem)', margin: '1rem', boxSizing: 'border-box' }}
                    size="small"
                    label="Player"
                    options={props.players}
                    optionLabel={p => `${p.firstName} ${p.lastName}`}
                    value={props.player ? props.player.player : null}
                    variant="standard"
                    onChange={handlePlayerChange}
                />
                <TimeInput
                    style={{ width: 'calc(100% - 2rem)', margin: '1rem', boxSizing: 'border-box' }}
                    size="small"
                    label="Minutes played"
                    value={props.player ? props.player.time : ''}
                    onChange={handleTimeChange}
                />
                <FormControl style={{ width: 'calc(100% - 2rem)', margin: '1rem', boxSizing: 'border-box' }}>
                    <InputLabel>Penalty cards</InputLabel>
                    <Select size="small" onChange={handleCardsChange} value={getCardsSelectValue()}>
                        <MenuItem value="n">No card</MenuItem>
                        <MenuItem value="y">Yellow card</MenuItem>
                        <MenuItem value="yr">Yellow and then red cards</MenuItem>
                        <MenuItem value="r">Direct red card</MenuItem>
                    </Select>
                    {yellowCard ? (
                        <GameTimeInput
                            label="Yellow card minute"
                            size="small"
                            timeValue={yellowCard.time}
                            extraTimeValue={yellowCard.extraTime}
                            onTimeChange={v => handleCardTimeChange('y', 'time', v)}
                            onExtraTimeChange={v => handleCardTimeChange('y', 'extraTime', v)}
                        />
                    ) : null}
                    {redCard ? (
                        <GameTimeInput
                            label="Red card minute"
                            size="small"
                            timeValue={redCard.time}
                            extraTimeValue={redCard.extraTime}
                            onTimeChange={v => handleCardTimeChange('r', 'time', v)}
                            onExtraTimeChange={v => handleCardTimeChange('r', 'extraTime', v)}
                        />
                    ) : null}
                </FormControl>
                {props.player && !props.sub && (props.captainable || props.player.captain) ? (
                    <Checkbox label="Captain?" value={props.player.captain} onChange={handleCaptainChanged} />
                ) : null}
                {props.sub ? (
                    <AutocompleteSelect
                        style={{ width: 'calc(100% - 2rem)', margin: '1rem', boxSizing: 'border-box' }}
                        size="small"
                        label="Replaces..."
                        options={props.substitutablePlayers}
                        optionLabel={p => `${p.firstName} ${p.lastName}`}
                        value={props.player ? props.player.replaces : null}
                        onChange={handleReplacesChange}
                    />
                ) : null}
                {props.sub && props.player && props.player.replaces ? (
                    <TimeInput
                        style={{ width: 'calc(100% - 2rem)', margin: '1rem', boxSizing: 'border-box' }}
                        size="small"
                        label="Replacement minute"
                        value={props.player.replacesAt}
                        onChange={handleReplacesAtChange}
                    />
                ) : null}
                <Collection
                    label="Goals"
                    itemComponent={GamePlayerGoal}
                    itemTemplate={{
                        time: '',
                        extraTime: '',
                        assistedBy: null,
                        penalty: false,
                        videoLink: ''
                    }}
                    itemProps={{ playerOptions: props.substitutablePlayers || [] }}
                    value={props.player ? props.player.goals : []}
                    onChange={handleGoalsChange}
                />
            </div>
            <DialogActions>
                <Button onClick={handleRemoveClick} color="secondary">
                    Remove
                </Button>
            </DialogActions>
        </Dialog>
    </div>;
};
