import { get } from 'lodash';
import Input from '../components/form/Input';
import PictureInput from '../components/form/PictureInput';
import AutocompleteSelect from '../components/form/AutocompleteSelect';
import NationalityModel from '../models/NationalityModel';
import Checkbox from '../components/form/Checkbox';
import RefereeModel from '../models/RefereeModel';
import { RefereeIcon } from '../components/Icons';

export default {
    baseUrl: '/referees',
    icon: RefereeIcon,
    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
    titles: {
        list: 'Referees',
        create: 'Add a new referee',
        update: 'Update %first_name% %last_name%',
        delete: 'Delete %first_name% %last_name%',
        vars: {
            '%first_name%': 'firstName',
            '%last_name%': 'lastName'
        }
    },
    calls: {
        list: RefereeModel.list,
        create: RefereeModel.create,
        update: RefereeModel.edit,
        delete: RefereeModel.remove,
        read: RefereeModel.read
    },
    list: {
        displayedColumns: [
            {
                field: 'firstName',
                title: 'First name'
            },
            {
                field: 'lastName',
                title: 'Last name'
            }
        ],
        filters: [
            {
                title: 'First name',
                filter: (item, value) => get(item, 'firstName').toLowerCase().indexOf(value.toLowerCase()) !== -1
            },
            {
                title: 'Last name',
                filter: (item, value) => get(item, 'lastName').toLowerCase().indexOf(value.toLowerCase()) !== -1
            }
        ],
        paginate: true,
        rowsPerPage: 20,
    },
    form: {
        blocks: [
            [
                {
                    component: PictureInput,
                    field: 'picture',
                    props: {
                        label: 'Picture'
                    },
                    externalObjectProps: {
                        placeholderPicture: 'picture.displayPath'
                    },
                    defaultValue: null
                }
            ],
            [
                {
                    component: Input,
                    field: 'firstName',
                    props: {
                        label: 'First name'
                    }
                },
                {
                    component: Input,
                    field: 'lastName',
                    props: {
                        label: 'Last name'
                    }
                },
                {
                    component: Input,
                    field: 'completeName',
                    props: {
                        label: 'Complete name'
                    }
                }
            ],
            [
                {
                    component: AutocompleteSelect,
                    field: 'nationalities',
                    props: {
                        label: 'Nationalities',
                        optionLabel: o => o ? o.name : '',
                        multiple: true
                    },
                    defaultValue: [],
                    optionsProvider: 'nationality'
                }
            ],
            [
                {
                    component: Checkbox,
                    field: 'published',
                    props: {
                        label: 'Published?'
                    },
                    defaultValue: false
                }
            ]
        ],
        providers: {
            nationality: NationalityModel.list
        }
    }
}
