import { get } from 'lodash';
import Input from '../components/form/Input';
import TranslatableInput from '../components/form/TranslatableInput';
import CollectorItemType from '../models/CollectorItemType';
import SeasonModel from '../models/SeasonModel';
import PictureInput from '../components/form/PictureInput';
import Collection from '../components/form/Collection';
import CollectorItem from '../components/form/CollectorItem';
import SeasonRankings from '../components/form/SeasonRankings';
import { UiCalendarIcon } from '../components/Icons';

export default {
    baseUrl: '/seasons',
    icon: UiCalendarIcon,
    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_TRANSLATOR'],
    titles: {
        list: 'Seasons',
        create: 'Add a new season',
        update: 'Update %season%',
        delete: 'Delete %season%',
        vars: {
            '%season%': 'season'
        }
    },
    calls: {
        list: SeasonModel.list,
        create: SeasonModel.create,
        update: SeasonModel.edit,
        delete: SeasonModel.remove,
        read: SeasonModel.read
    },
    list: {
        displayedColumns: [
            {
                field: 'season',
                title: 'Season'
            }
        ],
        filters: [
            {
                title: 'Season',
                filter: (item, value) => get(item, 'season').toLowerCase().indexOf(value.toLowerCase()) !== -1
            }
        ],
        paginate: true,
        rowsPerPage: 20,
    },
    create: {
        roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
    },
    delete: {
        roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
    },
    form: {
        blocks: [
            [
                {
                    component: PictureInput,
                    field: 'picture',
                    props: {
                        label: 'Picture'
                    },
                    externalObjectProps: {
                        placeholderPicture: 'picture.displayPath'
                    },
                    defaultValue: null,
                    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
                }
            ],
            [
                {
                    component: Input,
                    field: 'season',
                    props: {
                        label: 'Season'
                    },
                    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
                }
            ],
            [
                {
                    component: TranslatableInput,
                    field: 'description',
                    props: {
                        label: 'Description',
                        multiline: true
                    },
                    defaultValue: []
                }
            ],
            [
                {
                    component: PictureInput,
                    field: 'shirtPicture',
                    props: {
                        label: 'Shirt picture'
                    },
                    externalObjectProps: {
                        placeholderPicture: 'shirtPicture.displayPath'
                    },
                    defaultValue: null,
                    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
                },
                {
                    component: PictureInput,
                    field: 'teamPicture',
                    props: {
                        label: 'Team picture'
                    },
                    externalObjectProps: {
                        placeholderPicture: 'teamPicture.displayPath'
                    },
                    defaultValue: null,
                    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
                }
            ],
            [
                {
                    component: SeasonRankings,
                    field: 'rankings',
                    props: {
                        label: 'Rankings'
                    },
                    defaultValue: [],
                    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
                }
            ],
            [
                {
                    component: Collection,
                    field: 'collectorItems',
                    props: {
                        label: 'Collector items',
                        itemComponent: CollectorItem,
                        itemTemplate: {
                            picture: null,
                            type: null
                        },
                        inline: true
                    },
                    defaultValue: [],
                    optionsProvider: 'collectorItemType',
                    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
                }
            ]
        ],
        providers: {
            collectorItemType: CollectorItemType.list
        }
    }
}
