import React from 'react';
import '../vendor/icofont/icofont.min.css';

const AddressBookIcon = () => <i style={{ fontSize: '2rem' }} className="icofont-address-book" />;
const Briefcase1Icon = () => <i style={{ fontSize: '2rem' }} className="icofont-briefcase-1" />;
const BusinessmanIcon = () => <i style={{ fontSize: '2rem' }} className="icofont-businessman" />;
const ChartLineIcon = () => <i style={{ fontSize: '2rem' }} className="icofont-chart-line" />;
const ChartFlowIcon = () => <i style={{ fontSize: '2rem' }} className="icofont-chart-flow" />;
const FlagIcon = () => <i style={{ fontSize: '2rem' }} className="icofont-flag" />;
const FunkyManIcon = () => <i style={{ fontSize: '2rem' }} className="icofont-funky-man" />;
const GoalIcon = () => <i style={{ fontSize: '2rem' }} className="icofont-goal" />;
const HoldingHandsIcon = () => <i style={{ fontSize: '2rem' }} className="icofont-holding-hands" />;
const JerseyIcon = () => <i style={{ fontSize: '2rem' }} className="icofont-jersey" />;
const KickIcon = () => <i style={{ fontSize: '2rem' }} className="icofont-kick" />;
const LinkIcon = () => <i style={{ fontSize: '2rem' }} className="icofont-link" />;
const MicIcon = () => <i style={{ fontSize: '2rem' }} className="icofont-mic" />;
const NewsIcon = () => <i style={{ fontSize: '2rem' }} className="icofont-news" />;
const NewspaperIcon = () => <i style={{ fontSize: '2rem' }} className="icofont-newspaper" />;
const PaperIcon = () => <i style={{ fontSize: '2rem' }} className="icofont-paper" />;
const PapersIcon = () => <i style={{ fontSize: '2rem' }} className="icofont-papers" />;
const PictureIcon = () => <i style={{ fontSize: '2rem' }} className="icofont-picture" />;
const RefereeIcon = () => <i style={{ fontSize: '2rem' }} className="icofont-referee" />;
const SpinnerAlt5Icon = () => <i style={{ fontSize: '2rem' }} className="icofont-spinner-alt-5" />;
const TeamIcon = () => <i style={{ fontSize: '2rem' }} className="icofont-team" />;
const TrophyAltIcon = () => <i style={{ fontSize: '2rem' }} className="icofont-trophy-alt" />;
const UiCalendarIcon = () => <i style={{ fontSize: '2rem' }} className="icofont-ui-calendar" />;
const UiSettingsIcon = () => <i style={{ fontSize: '2rem' }} className="icofont-ui-settings" />;
const UiSocialLinkIcon = () => <i style={{ fontSize: '2rem' }} className="icofont-ui-social-link" />;
const UiUserGroupIcon = () => <i style={{ fontSize: '2rem' }} className="icofont-ui-user-group" />;
const UserIcon = () => <i style={{ fontSize: '2rem' }} className="icofont-ui-user" />;

export {
    AddressBookIcon,
    Briefcase1Icon,
    BusinessmanIcon,
    ChartFlowIcon,
    ChartLineIcon,
    FlagIcon,
    FunkyManIcon,
    GoalIcon,
    HoldingHandsIcon,
    JerseyIcon,
    KickIcon,
    LinkIcon,
    MicIcon,
    NewsIcon,
    NewspaperIcon,
    PaperIcon,
    PapersIcon,
    PictureIcon,
    RefereeIcon,
    SpinnerAlt5Icon,
    TeamIcon,
    TrophyAltIcon,
    UiCalendarIcon,
    UiSettingsIcon,
    UiSocialLinkIcon,
    UiUserGroupIcon,
    UserIcon
};
