import { get } from 'lodash';
import GameModel from '../models/GameModel';
import DateTimePicker from '../components/form/DateTimePicker';
import { defaultDateTime } from '../helpers/dateHelper';
import Checkbox from '../components/form/Checkbox';
import AutocompleteSelect from '../components/form/AutocompleteSelect';
import CompetitionModel from '../models/CompetitionModel';
import TurnModel from '../models/TurnModel';
import ClubModel from '../models/ClubModel';
import Input from '../components/form/Input';
import RefereeModel from '../models/RefereeModel';
import TranslatableInput from '../components/form/TranslatableInput';
import GamePlayers from '../components/form/GamePlayers';
import OpponentGoal from '../components/form/OpponentGoal';
import OpponentScorerModel from '../models/OpponentScorerModel';
import OpponentOwnGoal from '../components/form/OpponentOwnGoal';
import Collection from '../components/form/Collection';
import CollectorItem from '../components/form/CollectorItem';
import CollectorItemType from '../models/CollectorItemType';
import OpponentGoals from '../components/form/OpponentGoals';
import { KickIcon } from '../components/Icons';
import StadiumInput from '../components/form/StadiumInput';
import SeasonModel from '../models/SeasonModel';
import GameSeason from '../components/form/GameSeason';
import MediumModel from '../models/MediumModel';
import Archive from '../components/form/Archive';
import RankingModel from "../models/RankingModel";
import ArticleModel from '../models/ArticleModel'

export default {
    baseUrl: '/games',
    icon: KickIcon,
    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_TRANSLATOR'],
    titles: {
        list: 'Games',
        create: 'Add a new game',
        update: 'Update %sum_up%',
        delete: 'Delete %sum_up%',
        vars: {
            '%sum_up%': 'sumUp'
        }
    },
    calls: {
        list: GameModel.list,
        create: GameModel.create,
        update: GameModel.edit,
        delete: GameModel.remove,
        read: GameModel.read
    },
    list: {
        displayedColumns: [
            {
                field: 'sumUp',
                title: 'Game sum up'
            }
        ],
        filters: [
            {
                title: 'Sum up',
                filter: (item, value) => get(item, 'sumUp').toLowerCase().indexOf(value.toLowerCase()) !== -1
            }
        ],
        paginate: true,
        rowsPerPage: 20,
    },
    create: {
        roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
    },
    delete: {
        roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
    },
    form: {
        blocks: [
            [
                {
                    component: DateTimePicker,
                    field: 'date',
                    props: {
                        label: 'Date'
                    },
                    defaultValue: defaultDateTime(new Date()),
                    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
                },
                {
                    component: Checkbox,
                    field: 'isHome',
                    props: {
                        label: 'Home (or home team if neutral)?'
                    },
                    defaultValue: false,
                    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
                },
                {
                    component: Checkbox,
                    field: 'isNeutral',
                    props: {
                        label: 'Neutral?'
                    },
                    defaultValue: false,
                    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
                }
            ],
            [
                {
                    component: AutocompleteSelect,
                    field: 'competition',
                    props: {
                        label: 'Competition',
                        optionLabel: c => c ? c.name : ''
                    },
                    defaultValue: null,
                    optionsProvider: 'competition',
                    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
                },
                {
                    component: AutocompleteSelect,
                    field: 'turn',
                    props: {
                        label: 'Turn',
                        optionLabel: t => t ? t.name : ''
                    },
                    defaultValue: null,
                    optionsProvider: 'turn',
                    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
                },
                {
                    component: AutocompleteSelect,
                    field: 'opponent',
                    props: {
                        label: 'Opponent',
                        optionLabel: c => c ? c.shortName : ''
                    },
                    defaultValue: null,
                    optionsProvider: 'club',
                    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
                }
            ],
            [
                {
                    component: StadiumInput,
                    field: 'stadium',
                    props: {
                        label: 'Stadium'
                    },
                    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
                    externalObjectProps: {
                        date: 'date',
                        opponentId: 'opponent.id',
                        isHome: 'isHome'
                    }
                },
                {
                    component: Input,
                    field: 'crowd',
                    props: {
                        label: 'Attendance'
                    },
                    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
                }
            ],
            [
                {
                    component: AutocompleteSelect,
                    field: 'referee',
                    props: {
                        label: 'Referee',
                        optionLabel: r => r ? `${r.firstName} ${r.lastName}` : ''
                    },
                    defaultValue: null,
                    optionsProvider: 'referee',
                    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
                },
                {
                    component: AutocompleteSelect,
                    field: 'rankingAfterGame',
                    props: {
                        label: 'Ranking after game',
                        optionLabel: r => r ? r.name : ''
                    },
                    defaultValue: null,
                    optionsProvider: 'ranking',
                    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
                }
            ],
            [
                {
                    component: Input,
                    field: 'score',
                    props: {
                        label: 'Team score',
                        type: 'number',
                        min: 0
                    },
                    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
                },
                {
                    component: Input,
                    field: 'opponentScore',
                    props: {
                        label: 'Opponent score',
                        type: 'number',
                        min: 0
                    },
                    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
                },
                {
                    component: Input,
                    field: 'psoScore',
                    props: {
                        label: 'Team penalty shoot-out score'
                    },
                    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
                },
                {
                    component: Input,
                    field: 'opponentPsoScore',
                    props: {
                        label: 'Opponent penalty shoot-out score'
                    },
                    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
                }
            ],
            [
                {
                    component: Input,
                    field: 'videoLink',
                    props: {
                        label: 'Video link'
                    },
                    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
                },
                {
                    component: Input,
                    field: 'videoPlaylistLink',
                    props: {
                        label: 'Video playlist link'
                    },
                    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
                },
                {
                    component: Input,
                    field: 'galleryLink',
                    props: {
                        label: 'Photo gallery link'
                    },
                    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
                }
            ],
            [
                {
                    component: TranslatableInput,
                    field: 'additionalInfo',
                    props: {
                        label: 'Additional info',
                        multiline: true
                    },
                    defaultValue: []
                }
            ],
            [
                {
                    component: GamePlayers,
                    field: 'players',
                    defaultValue: [],
                    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
                    externalObjectProps: {
                        date: 'date'
                    }
                }
            ],
            [
                {
                    component: OpponentGoals,
                    field: 'opponentGoals',
                    props: {
                        label: 'Opponent goals',
                        itemComponent: OpponentGoal,
                        itemTemplate: {
                            scorer: null,
                            time: '',
                            extraTime: '',
                            penalty: false,
                            ownGoal: false
                        }
                    },
                    defaultValue: [],
                    optionsProvider: 'opponentScorer',
                    refreshable: true,
                    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
                },
                {
                    component: OpponentGoals,
                    field: 'opponentOwnGoals',
                    props: {
                        label: 'Opponent own goals',
                        itemComponent: OpponentOwnGoal,
                        itemTemplate: {
                            scorer: null,
                            time: '',
                            extraTime: ''
                        }
                    },
                    defaultValue: [],
                    optionsProvider: 'opponentScorer',
                    refreshable: true,
                    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
                }
            ],
            [
                {
                    component: Collection,
                    field: 'collectorItems',
                    props: {
                        label: 'Collector items',
                        itemComponent: CollectorItem,
                        itemTemplate: {
                            picture: null,
                            type: null
                        },
                        inline: true
                    },
                    defaultValue: [],
                    optionsProvider: 'collectorItemType',
                    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
                },
                {
                    component: Collection,
                    field: 'archives',
                    props: {
                        label: 'Archives',
                        itemComponent: Archive,
                        itemTemplate: {
                            picture: null,
                            medium: null
                        },
                        inline: true
                    },
                    defaultValue: [],
                    optionsProvider: 'medium',
                    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
                }
            ],
            [
                {
                    component: AutocompleteSelect,
                    field: 'article',
                    props: {
                        label: 'Article',
                        optionLabel: a => a ? a.title : '',
                        multiple: false
                    },
                    defaultValue: null,
                    optionsProvider: 'article',
                    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
                }
            ],
            [
                {
                    component: GameSeason,
                    field: 'season',
                    props: {
                        label: 'Season',
                        optionLabel: s => s ? s.season : ''
                    },
                    externalObjectProps: {
                        date: 'date'
                    },
                    defaultValue: null,
                    optionsProvider: 'season',
                    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
                }
            ],
            [
                {
                    component: Checkbox,
                    field: 'published',
                    props: {
                        label: 'Published?'
                    },
                    defaultValue: false,
                    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
                }
            ]
        ],
        providers: {
            article: ArticleModel.list,
            competition: CompetitionModel.list,
            turn: TurnModel.list,
            club: ClubModel.list,
            referee: RefereeModel.list,
            opponentScorer: OpponentScorerModel.list,
            collectorItemType: CollectorItemType.list,
            season: SeasonModel.list,
            medium: MediumModel.list,
            ranking: RankingModel.list
        }
    }
}
