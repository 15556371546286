import ClubFormDefinition from './ClubFormDefinition';
import OpponentScorerFormDefinition from './OpponentScorerFormDefinition';
import ArticleCategoryFormDefinition from './ArticleCategoryFormDefinition';
import ContributorFormDefinition from './ContributorFormDefinition';
import PartnerFormDefinition from './PartnerFormDefinition';
import CompetitionFormDefinition from './CompetitionFormDefinition';
import SocialNetworkLinkFormDefinition from './SocialNetworkLinkFormDefinition';
import GameFormDefinition from './GameFormDefinition';
import PlayerFormDefinition from './PlayerFormDefinition';
import LeaderFormDefinition from './LeaderFormDefinition';
import RefereeFormDefinition from './RefereeFormDefinition';
import SeasonFormDefinition from './SeasonFormDefinition';
import ArticleFormDefinition from './ArticleFormDefinition';
import LinkFormDefinition from './LinkFormDefinition';
import HomeBackgroundFormDefinition from './HomeBackgroundFormDefinition';
import GameGroupFormDefinition from './GameGroupFormDefinition';
import TransferRumorFormDefinition from './TransferRumorFormDefinition';
import NationalityFormDefinition from './NationalityFormDefinition';
import RoleFormDefinition from './RoleFormDefinition';
import TurnFormDefinition from './TurnFormDefinition';
import GroupPlayerTypeFormDefinition from './GroupPlayerTypeFormDefinition';
import ArchiveFormDefinition from './ArchiveFormDefinition';
import UserFormDefinition from './UserFormDefinition';
import MediumFormDefinition from './MediumFormDefinition';
import ConfigFormDefinition from './ConfigFormDefinition';
import SeasonBoundingFormDefinition from './SeasonBoundingFormDefinition';
import RankingFormDefinition from './RankingFormDefinition';

export default [
    GameFormDefinition,
    PlayerFormDefinition,
    LeaderFormDefinition,
    ClubFormDefinition,
    SeasonFormDefinition,
    RefereeFormDefinition,
    ArchiveFormDefinition,
    OpponentScorerFormDefinition,
    ArticleFormDefinition,
    ArticleCategoryFormDefinition,
    HomeBackgroundFormDefinition,
    ContributorFormDefinition,
    LinkFormDefinition,
    PartnerFormDefinition,
    GameGroupFormDefinition,
    TransferRumorFormDefinition,
    NationalityFormDefinition,
    RoleFormDefinition,
    CompetitionFormDefinition,
    TurnFormDefinition,
    GroupPlayerTypeFormDefinition,
    SocialNetworkLinkFormDefinition,
    MediumFormDefinition,
    RankingFormDefinition,
    UserFormDefinition,
    SeasonBoundingFormDefinition,
    ConfigFormDefinition
];
