import { get, range } from 'lodash';
import Input from '../components/form/Input';
import PlayerModel from '../models/PlayerModel';
import PictureInput from '../components/form/PictureInput';
import { defaultDate } from '../helpers/dateHelper';
import DatePicker from '../components/form/DatePicker';
import AutocompleteSelect from '../components/form/AutocompleteSelect';
import NationalityModel from '../models/NationalityModel';
import PositionModel from '../models/PositionModel';
import Checkbox from '../components/form/Checkbox';
import InputCollection from '../components/form/InputCollection';
import ScalarAutocompleteSelect from '../components/form/ScalarAutocompleteSelect';
import TranslatableInput from '../components/form/TranslatableInput';
import PlayerTransfers from '../components/form/PlayerTransfers';
import { TeamIcon } from '../components/Icons';
import PlayerId from '../components/form/PlayerId';
import ArticleModel from '../models/ArticleModel'

export default {
    baseUrl: '/players',
    icon: TeamIcon,
    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_TRANSLATOR'],
    titles: {
        list: 'Players',
        create: 'Add a new player',
        update: 'Update %first_name% %last_name%',
        delete: 'Delete %first_name% %last_name%',
        vars: {
            '%first_name%': 'firstName',
            '%last_name%': 'lastName'
        }
    },
    calls: {
        list: PlayerModel.list,
        create: PlayerModel.create,
        update: PlayerModel.edit,
        delete: PlayerModel.remove,
        read: PlayerModel.read
    },
    list: {
        displayedColumns: [
            {
                field: 'firstName',
                title: 'First name'
            },
            {
                field: 'lastName',
                title: 'Last name'
            }
        ],
        filters: [
            {
                title: 'First name',
                filter: (item, value) => get(item, 'firstName').toLowerCase().indexOf(value.toLowerCase()) !== -1
            },
            {
                title: 'Last name',
                filter: (item, value) => get(item, 'lastName').toLowerCase().indexOf(value.toLowerCase()) !== -1
            }
        ],
        paginate: true,
        rowsPerPage: 20,
    },
    create: {
        roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
    },
    delete: {
        roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
    },
    form: {
        blocks: [
            [
                {
                    component: PictureInput,
                    field: 'picture',
                    props: {
                        label: 'Picture'
                    },
                    externalObjectProps: {
                        placeholderPicture: 'picture.displayPath'
                    },
                    defaultValue: null,
                    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
                }
            ],
            [
                {
                    component: Input,
                    field: 'firstName',
                    props: {
                        label: 'First name'
                    },
                    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
                },
                {
                    component: Input,
                    field: 'lastName',
                    props: {
                        label: 'Last name'
                    },
                    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
                },
                {
                    component: Input,
                    field: 'completeName',
                    props: {
                        label: 'Complete name'
                    },
                    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
                }
            ],
            [
                {
                    component: DatePicker,
                    field: 'birthDate',
                    props: {
                        label: 'Birth date'
                    },
                    defaultValue: defaultDate(new Date()),
                    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
                },
                {
                    component: DatePicker,
                    field: 'deathDate',
                    props: {
                        label: 'Death date'
                    },
                    defaultValue: null,
                    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
                },
                {
                    component: Input,
                    field: 'birthTown',
                    props: {
                        label: 'Birth town'
                    },
                    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
                },
                {
                    component: AutocompleteSelect,
                    field: 'birthCountry',
                    props: {
                        label: 'Birth country',
                        optionLabel: o => o ? o.name : ''
                    },
                    defaultValue: null,
                    optionsProvider: 'nationality',
                    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
                }
            ],
            [
                {
                    component: AutocompleteSelect,
                    field: 'nationalities',
                    props: {
                        label: 'Nationalities',
                        optionLabel: o => o ? o.name : '',
                        multiple: true
                    },
                    defaultValue: [],
                    optionsProvider: 'nationality',
                    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
                }
            ],
            [
                {
                    component: Input,
                    field: 'size',
                    props: {
                        label: 'Size'
                    },
                    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
                },
                {
                    component: Input,
                    field: 'weight',
                    props: {
                        label: 'Weight'
                    },
                    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
                }
            ],
            [
                {
                    component: AutocompleteSelect,
                    field: 'position',
                    props: {
                        label: 'Position',
                        optionLabel: o => o ? o.name : ''
                    },
                    defaultValue: null,
                    optionsProvider: 'position',
                    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
                },
                {
                    component: AutocompleteSelect,
                    field: 'secondaryPositions',
                    props: {
                        label: 'Secondary positions',
                        optionLabel: o => o ? o.name : '',
                        multiple: true
                    },
                    defaultValue: [],
                    optionsProvider: 'position',
                    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
                }
            ],
            [
                {
                    component: Input,
                    field: 'contract',
                    props: {
                        label: 'End of contract'
                    },
                    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
                },
                {
                    component: Checkbox,
                    field: 'activity',
                    props: {
                        label: 'Still in activity?'
                    },
                    defaultValue: false,
                    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
                }
            ],
            [
                {
                    component: InputCollection,
                    field: 'honours',
                    props: {
                        label: 'Honours'
                    },
                    defaultValue: [],
                    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
                },
                {
                    component: InputCollection,
                    field: 'selections',
                    props: {
                        label: 'Selections'
                    },
                    defaultValue: [],
                    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
                }
            ],
            [
                {
                    component: ScalarAutocompleteSelect,
                    field: 'numbers',
                    props: {
                        label: 'Shirt numbers',
                        options: range(1, 100).map(n => n.toString()),
                        optionLabel: o => o,
                        multiple: true
                    },
                    defaultValue: [],
                    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
                }
            ],
            [
                {
                    component: TranslatableInput,
                    field: 'description',
                    props: {
                        label: 'Description',
                        multiline: true
                    },
                    defaultValue: []
                }
            ],
            [
                {
                    component: PlayerTransfers,
                    field: 'transfers',
                    props: {
                        label: 'Transfers'
                    },
                    defaultValue: [],
                    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
                }
            ],
            [
                {
                    component: Input,
                    field: 'twitterAccount',
                    props: {
                        label: 'Twitter account'
                    },
                    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
                }
            ],
            [
                {
                    component: AutocompleteSelect,
                    field: 'article',
                    props: {
                        label: 'Article',
                        optionLabel: a => a ? a.title : '',
                        multiple: false
                    },
                    defaultValue: null,
                    optionsProvider: 'article',
                    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
                }
            ],
            [
                {
                    component: Checkbox,
                    field: 'published',
                    props: {
                        label: 'Published?'
                    },
                    defaultValue: false,
                    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
                }
            ],
            [
                {
                    component: PlayerId,
                    field: 'id',
                    externalObjectProps: {
                        firstName: 'firstName',
                        lastName: 'lastName',
                        birthDate: 'birthDate'
                    },
                    roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
                }
            ]
        ],
        providers: {
            nationality: NationalityModel.list,
            position: PositionModel.list,
            article: ArticleModel.list
        }
    }
}
