import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default (props) => {
    const isOptionSelected = (o, v) => {
        if (o === null || v === null) {
            return o === null && v === null;
        }

        return o.id === v.id;
    };

    const handleChange = (e, val) => {
        if (val && val.id === 'new') {
            if (props.onRequireAdd) {
                props.onRequireAdd(val);
                e.preventDefault();
            }
        } else {
            props.onChange(val);
        }
    };

    return (
        <Autocomplete
            style={props.style || {}}
            options={props.options}
            value={props.value}
            size={props.size ? props.size : 'medium'}
            multiple={!!props.multiple}
            getOptionLabel={props.optionLabel}
            getOptionSelected={isOptionSelected}
            renderInput={params => <TextField {...params} label={props.label} variant={props.variant || 'outlined'} />}
            filterOptions={props.filterOptions}
            onChange={handleChange}
        />
    );
}
